import React, { PureComponent } from 'react';
import { StyleSheet } from 'react-native';

import AI from './activity-indicator';

export default class ButtonSpinner extends PureComponent {
  render() {
    return <AI style={styles.spinner} />;
  }
}

const styles = StyleSheet.create({
  spinner: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
});
