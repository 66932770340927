import {
  getAuth,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  fetchSignInMethodsForEmail,
  updatePassword,
  onAuthStateChanged,
  signInWithEmailLink,
} from 'firebase/auth';

import { FIREBASE_AUTH_ERRORS } from '../constants';

const auth = {
  getCurrentUser: async () => {
    const idToken = await getAuth().currentUser?.getIdToken(true);
    if (idToken) {
      const { email, uid: uuid } = (await getAuth().currentUser) || {};
      return { idToken, email, uuid };
    }

    return new Promise(resolve => {
      onAuthStateChanged(getAuth(), async user => {
        const idToken = await user?.getIdToken(true);
        if (idToken) {
          const { email, uid: uuid } = user || {};
          resolve({ idToken, email, uuid });
        }
      });

      // чтобы вечно не ждать резолва промиса, если вдруг что-то пойдет не так, тут установим таймаут
      setTimeout(() => {
        resolve(null);
      }, 3000);
    });
  },
  signInWithCustomToken: (custom_token: string) => {
    return signInWithCustomToken(getAuth(), custom_token);
  },
  signInWithEmailAndPassword: (email: string, password: string) => {
    return signInWithEmailAndPassword(getAuth(), email, password);
  },
  sendPasswordResetEmail: (email: string) => {
    return sendPasswordResetEmail(getAuth(), email);
  },
  signInWithEmailLink: (email: string, emailLink: string) => {
    return signInWithEmailLink(getAuth(), email, emailLink);
  },
  getFirebaseErrorCode: (input: string): FIREBASE_AUTH_ERRORS => {
    if (input.includes('auth/user-not-found')) {
      return FIREBASE_AUTH_ERRORS.USER_NOT_FOUND;
    }

    if (input.includes('auth/invalid-email') || input.includes('Email not found')) {
      return FIREBASE_AUTH_ERRORS.INVALID_EMAIL;
    }

    if (input.includes('auth/wrong-password')) {
      return FIREBASE_AUTH_ERRORS.WRONG_PASSWORD;
    }

    if (input.includes('auth/too-many-requests')) {
      return FIREBASE_AUTH_ERRORS.TOO_MANY;
    }

    return FIREBASE_AUTH_ERRORS.DEFAULT;
  },
  getSingInMethods: (email: string) => {
    return fetchSignInMethodsForEmail(getAuth(), email);
  },
  updateUserPassword: (password: string) => {
    const user = getAuth()?.currentUser;

    if (!user) {
      return false;
    }

    return updatePassword(user, password);
  },
};

export default auth;
