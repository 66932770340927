import { color, paddingVertical, sw, paddingHorizontal, fs } from '@wowmaking/ui/src/utils';
import React, { createRef, FC, useState } from 'react';
import { View, ScrollView, TouchableOpacity, TextInput, StyleSheet, Image } from 'react-native';
import Animated, { FadeIn, FadeOut, interpolate, interpolateColor, useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated';

import Button from 'components/button';
import Text from 'components/text';
import { BEIGE_2, DARK_GREY_BLUE, BEIGE, AQUA } from 'constants/colors';
import { t } from 'localization';
import { closeCompleteRegistrationModal } from 'modules/auth/store/actions';
import { useAppDispatch, useAppSelector } from 'store';
import { isPasswordValid } from 'utils/strings';
import Analytics from 'analytics';
import CLOSE from 'assets/icons/close.png';
import { OpenSans } from 'constants/fonts';

import Undone from '../icons/undone.svg';
import Done from '../icons/done.svg';
import Eye from '../icons/eye.svg';
import EyeSlash from '../icons/eye-slash.svg';

const BEIGE_05 = color(BEIGE_2, 0.5);

interface Props {
  loading?: boolean;
  onPress: (password: string) => void;
}

const Form: FC<Props> = ({ onPress, loading = false }) => {
  const dispatch = useAppDispatch();
  const email = useAppSelector(state => state.auth?.firebaseEmail); // именно мыло из фаера

  const [password, setPassword] = useState('');
  const [isPassword, setPasswordVisibility] = useState(true);
  const [validationPoints, setValidationPoints] = useState({
    length: false,
    symbols: false,
  });

  const scrollRef = createRef<ScrollView>();

  const validation = useSharedValue(0);
  const length = useSharedValue(0);
  const symbols = useSharedValue(0);

  const borderAnimatedStyles = useAnimatedStyle(() => {
    return {
      borderBottomColor: interpolateColor(validation.value, [0, 1], [BEIGE_05, BEIGE_2]),
    };
  });

  const validationAnimatedStyles = useAnimatedStyle(() => {
    return {
      maxHeight: interpolate(validation.value, [0, 1], [0, 999]),
    };
  });

  const lengthAnimatedTextStyles = useAnimatedStyle(() => {
    return {
      color: interpolateColor(length.value, [0, 1], [BEIGE_05, AQUA]),
    };
  });

  const symbolsAnimatedTextStyles = useAnimatedStyle(() => {
    return {
      color: interpolateColor(symbols.value, [0, 1], [BEIGE_05, AQUA]),
    };
  });

  const handleTextChange = (text: string) => {
    const isTextLengthValid = text.length >= 8;
    const isTextContainValidSymbols = isPasswordValid(text);

    length.value = withTiming(+isTextLengthValid);
    symbols.value = withTiming(+isTextContainValidSymbols);

    setValidationPoints({
      length: isTextLengthValid,
      symbols: isTextContainValidSymbols,
    });
    setPassword(text);
  };

  const handleFocus = () => {
    scrollRef.current?.scrollToEnd();
    validation.value = withTiming(1);
  };

  const handleBlur = () => {
    validation.value = withTiming(0);
  };

  const handlePasswordVisibility = () => {
    setPasswordVisibility(prev => !prev);
  };

  const handleClose = () => {
    Analytics.track('complete_registration_close');
    dispatch(closeCompleteRegistrationModal());
  };

  const handlePress = () => {
    onPress(password);
  };

  return (
    <ScrollView contentContainerStyle={styles.scroll} keyboardShouldPersistTaps="handled" ref={scrollRef}>
      <TouchableOpacity style={styles.close} onPress={handleClose}>
        <Image source={CLOSE} style={styles.closeIcon} />
      </TouchableOpacity>
      <View style={styles.content}>
        <Text font="Philosopher" style={styles.title}>
          {t('COMPLETE_REGISTRATION.TITLE')}
        </Text>
        <Text style={styles.subtitle}>{t('COMPLETE_REGISTRATION.SUBTITLE')}</Text>
        <View style={styles.emailContainer}>
          <Text style={styles.email}>{email}</Text>
        </View>
      </View>
      <View style={styles.form}>
        <Text style={styles.label}>{t('COMPLETE_REGISTRATION.TEXT_INPUT_LABEL')}</Text>
        <Animated.View style={[styles.inputContainer, borderAnimatedStyles]}>
          <TextInput
            style={styles.input}
            placeholderTextColor={color(BEIGE_2, 0.5)}
            placeholder={t('COMPLETE_REGISTRATION.TEXT_INPUT_PLACEHOLDER')}
            autoCapitalize="none"
            autoComplete="off"
            secureTextEntry={isPassword}
            autoCorrect={false}
            textContentType="password"
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChangeText={handleTextChange}
          />
          {!!password?.length && (
            <TouchableOpacity style={styles.eye} onPress={handlePasswordVisibility}>
              {isPassword ? <EyeSlash /> : <Eye />}
            </TouchableOpacity>
          )}
        </Animated.View>
        <Animated.View style={[styles.validation, validationAnimatedStyles]}>
          <View style={styles.validationPoint}>
            <Animated.View entering={FadeIn} exiting={FadeOut} key={`${validationPoints.length}_length`}>
              {validationPoints.length ? <Done /> : <Undone />}
            </Animated.View>
            <Animated.Text style={[styles.validationText, lengthAnimatedTextStyles]}>{t('COMPLETE_REGISTRATION.VALIDATION.LENGTH')}</Animated.Text>
          </View>
          <View style={styles.validationPoint}>
            <Animated.View entering={FadeIn} exiting={FadeOut} key={`${validationPoints.symbols}_symbols`}>
              {validationPoints.symbols ? <Done /> : <Undone />}
            </Animated.View>
            <Animated.Text style={[styles.validationText, symbolsAnimatedTextStyles]}>{t('COMPLETE_REGISTRATION.VALIDATION.SYMBOLS')}</Animated.Text>
          </View>
        </Animated.View>
      </View>
      <Button
        title={t('COMPLETE_REGISTRATION.BUTTON_TITLE')}
        onPress={handlePress}
        isSpinner={loading}
        disabled={!validationPoints.length || !validationPoints.symbols}
      />
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  scroll: {
    paddingBottom: paddingVertical(20),
    paddingHorizontal: paddingHorizontal(20),
  },
  close: {
    alignSelf: 'flex-end',
  },
  closeIcon: {
    tintColor: BEIGE_2,
  },
  content: {
    width: '100%',
  },
  title: {
    fontSize: fs(26),
    lineHeight: fs(33),
    fontWeight: '700',
    color: BEIGE_2,
    textAlign: 'center',
    marginTop: paddingVertical(10),
  },
  subtitle: {
    fontSize: fs(16),
    lineHeight: fs(23),
    fontWeight: '400',
    color: color(BEIGE_2, 0.8),
    textAlign: 'center',
    marginTop: paddingVertical(10),
  },
  emailContainer: {
    alignSelf: 'center',
    marginTop: paddingVertical(15),
    paddingHorizontal: paddingHorizontal(15),
    paddingVertical: paddingVertical(10),
    backgroundColor: DARK_GREY_BLUE,
    borderRadius: sw(10),
  },
  email: {
    fontSize: fs(16),
    fontWeight: '400',
    color: BEIGE_2,
  },
  form: {
    marginTop: paddingVertical(40),
    marginBottom: paddingVertical(30),
    marginHorizontal: paddingHorizontal(10),
  },
  label: {
    fontSize: fs(12),
    fontWeight: '600',
    color: BEIGE,
    marginBottom: paddingVertical(15),
  },
  input: {
    fontSize: fs(20),
    fontWeight: '400',
    color: BEIGE_2,
    flexGrow: 1,
    fontFamily: OpenSans,
  },
  inputContainer: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    borderBottomColor: BEIGE_05,
    borderBottomWidth: 2,
    paddingBottom: paddingVertical(5),
  },
  validation: {
    width: '100%',
    overflow: 'hidden',
    marginTop: paddingVertical(10),
  },
  validationPoint: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  validationText: {
    marginLeft: paddingHorizontal(5),
    fontSize: fs(12),
    fontWeight: '400',
    color: BEIGE_05,
  },
  eye: {
    marginLeft: paddingHorizontal(10),
    height: '100%',
  },
});

export default Form;
