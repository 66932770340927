import React, { FC } from 'react';
import { StyleSheet, Image, View } from 'react-native';
import { useDispatch } from 'react-redux';
import { paddingHorizontal, paddingVertical, fs } from '@wowmaking/ui/src/utils';

import { AppDispatch } from 'store';
import Analytics from 'analytics';
import { finalizeWebAuth } from 'modules/auth/store/actions';
import { t2 } from 'localization';
import Text from 'components/text';
import * as COLORS from 'constants/colors';

import Title from '../../onboarding/components/title';
import Button from '../../onboarding/components/button';
import { PADDING_TOP, PADDING_BOTTOM } from '../../onboarding/constants';
import IMG_SUCCESS from '../images/success.png';

function trackEvent(category: string, action: string, params?: Record<string, unknown>) {
  Analytics.trackEvent(category, action, params, { skip: ['facebook'] });
}

interface Props {
  email?: string;
  analytics?: string;
  initialUrl?: string;
  reload?: boolean;
  onClose?: () => any;
}

const AuthSuccessComponent: FC<Props> = ({ email, reload = false, initialUrl, analytics, onClose }) => {
  const dispatch = useDispatch<AppDispatch>();

  const handleDone = () => {
    trackEvent(analytics || 'Auth_Success', 'Done');
    dispatch(finalizeWebAuth(initialUrl, reload));

    if (typeof onClose === 'function') {
      onClose();
    }
  };

  return (
    <View style={styles.container}>
      <View style={styles.wrap}>
        <View style={styles.top}>
          <Image source={IMG_SUCCESS} style={styles.successImg} />

          <View style={styles.titleContainer}>
            <Title text={t2('AUTH_SUCCESS.TITLE')} style={styles.title} />

            {!!email && (
              <View style={styles.email}>
                <Text style={styles.emailText}>{email}</Text>
              </View>
            )}
          </View>
        </View>

        <View style={styles.buttonContainer}>
          <Button title={t2('AUTH_SUCCESS.BTN_TITLE')} onPress={handleDone} />
        </View>
      </View>
    </View>
  );
};

export default AuthSuccessComponent;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: PADDING_TOP,
    paddingBottom: PADDING_BOTTOM,
    backgroundColor: COLORS.DARK_BLUE_GREY_2,
  },
  wrap: {
    flex: 1,
    justifyContent: 'space-around',
  },
  top: {},
  titleContainer: {
    marginTop: paddingVertical(25),
    alignItems: 'center',
    paddingHorizontal: paddingHorizontal(30),
  },
  title: {
    maxWidth: '88%',
    fontSize: fs(26),
    lineHeight: fs(32),
  },
  email: {
    width: '100%',
    marginTop: paddingVertical(25),
    paddingHorizontal: paddingHorizontal(20),
    paddingVertical: paddingVertical(19),
    borderRadius: 15,
    backgroundColor: COLORS.DARK_TEAL,
    alignItems: 'center',
    justifyContent: 'center',
  },
  emailText: {
    fontSize: fs(16),
    color: COLORS.BEIGE_2,
    textAlign: 'center',
  },
  buttonContainer: {
    paddingHorizontal: paddingHorizontal(32),
    alignItems: 'center',
  },
  successImg: {
    alignSelf: 'center',
  },
});
