import { AUTH_TYPES, State, ActionType } from './types';

const initialState: State = {
  id: '',
  webUUID: '',
  oneTimeToken: '',
  accessToken: '',
  isSuccessful: false,
  forceLogout: false, // force navigate to AUTHORIZATION
  firebaseEmail: '',
  isRegistrationCompleted: false, // has email and password
  completeRegistrationShowsCounter: 0,
  isCompleteRegistrationShown: false,
};

export default function (state = initialState, action: ActionType): State {
  switch (action.type) {
    case AUTH_TYPES.SET_USER_CREDENTIALS:
      return {
        ...state,
        id: action.payload.id,
        oneTimeToken: '',
        accessToken: action.payload.accessToken,
        isSuccessful: true,
        forceLogout: false,
      };
    case AUTH_TYPES.SET_WEB_UUID:
      return {
        ...state,
        webUUID: action.payload,
      };
    case AUTH_TYPES.SET_ONE_TIME_TOKEN:
      return {
        ...state,
        accessToken: '',
        oneTimeToken: action.payload,
      };
    case AUTH_TYPES.SET_FIREBASE_EMAIL:
      return {
        ...state,
        firebaseEmail: action.payload,
      };
    case AUTH_TYPES.SET_FORCE_LOGOUT:
      return {
        ...state,
        forceLogout: true,
      };
    case AUTH_TYPES.SET_IS_REGISTRATION_COMPLETED:
      return {
        ...state,
        isRegistrationCompleted: action.payload,
      };
    case AUTH_TYPES.SET_COMPLETE_REGISTRATION_SHOW_COUNTER:
      return {
        ...state,
        completeRegistrationShowsCounter: action.payload,
      };
    case AUTH_TYPES.SET_COMPLETE_REGISTRATION_SHOWN:
      return {
        ...state,
        isCompleteRegistrationShown: action.payload,
      };
    case AUTH_TYPES.RESET_DATA:
      return {
        ...initialState,
      };
  }

  return state;
}
