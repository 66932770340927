import React, { FC } from 'react';
import { Image, StyleSheet, TouchableOpacity, View } from 'react-native';
import { fs, paddingHorizontal, paddingVertical, sw } from '@wowmaking/ui/src/utils';

import Text from 'components/text';
import { t } from 'localization';
import { useAppDispatch } from 'store';
import { closeCompleteRegistrationModal } from 'modules/auth/store/actions';
import Button from 'components/button';
import { BEIGE_2, GOLDEN } from 'constants/colors';
import CLOSE from 'assets/icons/close.png';

import SUCCESS from '../icons/success.png';

interface Props {}

const Success: FC<Props> = () => {
  const dispatch = useAppDispatch();

  const handlePress = () => {
    dispatch(closeCompleteRegistrationModal());
  };

  return (
    <View style={styles.container}>
      <TouchableOpacity style={styles.close} onPress={handlePress}>
        <Image source={CLOSE} style={styles.closeIcon} />
      </TouchableOpacity>
      <Text font="Philosopher" style={styles.title}>
        {t('COMPLETE_REGISTRATION.SUCCESS.TITLE')}
      </Text>
      <Image source={SUCCESS} style={styles.success} resizeMode="contain" />
      <Button onPress={handlePress} title={t('COMPLETE_REGISTRATION.SUCCESS.BUTTON')} style={styles.button} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    alignItems: 'center',
    paddingBottom: paddingVertical(20),
    paddingHorizontal: paddingHorizontal(20),
  },
  title: {
    color: GOLDEN,
    fontSize: fs(26),
    lineHeight: fs(33),
    fontWeight: '700',
    textAlign: 'center',
    marginTop: paddingVertical(5),
    paddingHorizontal: paddingHorizontal(10),
  },
  button: {
    marginTop: paddingVertical(40),
  },
  close: {
    alignSelf: 'flex-end',
  },
  closeIcon: {
    tintColor: BEIGE_2,
  },
  success: {
    height: sw(150),
    marginTop: paddingVertical(20),
  },
});

export default Success;
