import AsyncStorage from '@react-native-async-storage/async-storage';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';

import chat from './chat';
import core from './core';
import modals from './modals';
import time from './time';
import notifications from './notifications';
import onboarding from './onboarding';
import catalog from './catalog';
import services from './services';
import monetization from './monetization';

const ASTROLOGERS_PERSISTOR_KEY = 'astrologers';

const persistConfigs = {
  catalog: {
    timeout: 0,
    key: 'catalog',
    storage: AsyncStorage,
    keyPrefix: ASTROLOGERS_PERSISTOR_KEY,
  },
  chat: {
    timeout: 0,
    key: `${ASTROLOGERS_PERSISTOR_KEY}-chat`,
    storage: AsyncStorage,
    whitelist: ['chats'],
  },
  core: {
    timeout: 0,
    key: `${ASTROLOGERS_PERSISTOR_KEY}-core`,
    storage: AsyncStorage,
    whitelist: ['wasRequest', 'lastConnectionSessionNumber', 'promocode', 'isUnlimitedMode'],
  },
  notifications: {
    timeout: 0,
    key: `${ASTROLOGERS_PERSISTOR_KEY}-notifications`,
    storage: AsyncStorage,
    blacklist: ['isPressed', 'triggerNotifications'],
  },
  modals: {
    timeout: 0,
    key: `${ASTROLOGERS_PERSISTOR_KEY}-modals`,
    storage: AsyncStorage,
    whitelist: ['isReviewCompleted', 'astroeventCounters', 'freeConsultationShowed'],
  },
  time: {
    timeout: 0,
    key: `${ASTROLOGERS_PERSISTOR_KEY}-time`,
    storage: AsyncStorage,
    whitelist: ['seconds', 'freeSeconds', 'lastTransactionDate'],
  },
  onboarding: {
    timeout: 0,
    key: `${ASTROLOGERS_PERSISTOR_KEY}-onboarding`,
    storage: AsyncStorage,
    blacklist: ['step'],
  },
  services: {
    timeout: 0,
    key: `${ASTROLOGERS_PERSISTOR_KEY}-services`,
    storage: AsyncStorage,
    whitelist: [],
  },
  monetization: {
    timeout: 0,
    key: `${ASTROLOGERS_PERSISTOR_KEY}-monetization`,
    storage: AsyncStorage,
    whitelist: ['transactionData', 'renewData'],
  },
};

const astrologersReducer = combineReducers({
  catalog: persistReducer(persistConfigs.catalog, catalog),
  chat: persistReducer(persistConfigs.chat, chat),
  core: persistReducer(persistConfigs.core, core),
  modals: persistReducer(persistConfigs.modals, modals),
  time: persistReducer(persistConfigs.time, time),
  notifications: persistReducer(persistConfigs.notifications, notifications),
  onboarding: persistReducer(persistConfigs.onboarding, onboarding),
  services: persistReducer(persistConfigs.services, services),
  monetization: persistReducer(persistConfigs.monetization, monetization),
});

export default astrologersReducer;
