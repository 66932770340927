import React, { FC } from 'react';
import { StyleProp, ViewStyle, StyleSheet, Pressable, TextStyle } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import Animated, { useAnimatedStyle, useDerivedValue, withTiming, interpolate, Extrapolation } from 'react-native-reanimated';
import { sw, fs } from '@wowmaking/ui/src/utils';

import withHaptic from 'hoc/with-haptic';
import Text from 'components/text';
import * as COLORS from 'constants/colors';

const AnimatedPressable = Animated.createAnimatedComponent(Pressable);

const GRADIENT_START = { x: 0, y: 1 };
const GRADIENT_END = { x: 1, y: 0 };

interface Props {
  onPress: () => void;
  style?: StyleProp<ViewStyle>;
  title: string;
  titleStyle?: StyleProp<TextStyle>;
  disabled?: boolean;
  gradient?: (string | number)[];
}

const Button: FC<Props> = ({ onPress, style, title, titleStyle, disabled, gradient = COLORS.TEAL_GRADIENT }) => {
  const animatedValue = useDerivedValue(() => {
    return disabled ? withTiming(0) : withTiming(1);
  });

  const animatedStyle = useAnimatedStyle(() => {
    const opacity = interpolate(animatedValue.value, [1, 0], [1, 0.45], Extrapolation.CLAMP);
    return {
      opacity,
    };
  });

  const handlePress = () => {
    if (typeof onPress === 'function') {
      onPress();
    }
  };

  return (
    <AnimatedPressable style={[styles.root, animatedStyle, style]} onPress={handlePress}>
      <LinearGradient colors={gradient} style={styles.container} start={GRADIENT_START} end={GRADIENT_END}>
        <Text style={[styles.title, titleStyle]} font="Philosopher">
          {title}
        </Text>
      </LinearGradient>
    </AnimatedPressable>
  );
};

export default withHaptic(Button);

const styles = StyleSheet.create({
  root: {
    width: '100%',
    height: sw(50),
    borderRadius: sw(25),
  },
  container: {
    height: '100%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: sw(25),
  },
  title: {
    fontSize: fs(22),
    lineHeight: fs(24),
    color: COLORS.WHITE,
    textAlign: 'center',
    fontWeight: 'bold',
  },
});
