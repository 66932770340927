import React, { FC } from 'react';
import { StyleProp, TextStyle, StyleSheet } from 'react-native';
import { fs } from '@wowmaking/ui/src/utils';

import Text from 'components/text';
import * as COLORS from 'constants/colors';

interface Props {
  style?: StyleProp<TextStyle>;
  text: string;
}

const Title: FC<Props> = ({ style, text }) => (
  <Text style={[styles.root, style]} font="Philosopher">
    {text}
  </Text>
);

export default Title;

const styles = StyleSheet.create({
  root: {
    fontSize: fs(35),
    lineHeight: fs(39),
    color: COLORS.BEIGE_2,
    textAlign: 'center',
    fontWeight: '900',
  },
});
