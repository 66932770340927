import React, { FC, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import Modal from 'react-native-modal';
import { BOTTOM_OFFSET, paddingVertical, sw } from '@wowmaking/ui/src/utils';

import { useAppDispatch } from 'store';
import { DEFAULT_HEADER_OFFSET } from 'constants/sizes';
import useModalIsVisible from 'hooks/use-modal-is-visibly';
import { COMPLETE_REGISTRATION } from 'constants/modals';
import { setPasswordForAccount } from 'modules/auth/store/actions';
import Analytics from 'analytics';
import { DARK_TEAL } from 'constants/colors';

import Form from './components/form';
import Success from './components/success';

interface Props {}

const CompleteRegistration: FC<Props> = () => {
  const dispatch = useAppDispatch();
  const isVisible = useModalIsVisible(COMPLETE_REGISTRATION);

  const [isLoading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handlePress = async (password: string) => {
    Analytics.track('complete_registration_click');
    try {
      setLoading(true);

      await dispatch(setPasswordForAccount(password));

      Analytics.track('complete_registration_success');
      setSuccess(true);
    } catch (error) {
      Analytics.track('complete_registration_error', { message: (error as Error).message });
      console.log('ERROR CompleteRegistration handlePress', (error as Error).message);
    } finally {
      setLoading(false);
    }
  };

  const handleShow = () => {
    Analytics.track('complete_registration_show');
  };

  return (
    <Modal isVisible={isVisible} style={styles.wrapper} onShow={handleShow} avoidKeyboard>
      <View style={styles.container}>
        {!success && <Form onPress={handlePress} loading={isLoading} />}
        {success && <Success />}
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    marginHorizontal: 0,
    marginVertical: 0,
    paddingTop: DEFAULT_HEADER_OFFSET,
    justifyContent: 'flex-end',
  },
  container: {
    paddingTop: paddingVertical(20),
    borderTopLeftRadius: sw(40),
    borderTopRightRadius: sw(40),
    width: '100%',
    backgroundColor: DARK_TEAL,
    paddingBottom: BOTTOM_OFFSET,
  },
});

export default CompleteRegistration;
