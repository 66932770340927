import _ from 'lodash';
import { createAction } from 'redux-actions';

import { AppDispatch, AppGetState } from 'store';
import { FULL_WIDGETS_LIST } from 'constants/widgets';
import { sortRandom } from 'utils/seedrandom';
import { isToday } from 'utils/format-dates-in-horoscopes';

import { TYPES } from './types';

export const setShown = createAction(TYPES.SHOWN);
export const resetVisited = createAction(TYPES.RESET_VISITED);

export const initWidgets = () => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    let {
      widgets: { seed },
      remoteConfig: { remoteConfigParams = { todayWidgets: [], randomWidgetsSequence: false } },
    } = getState();
    const { todayWidgets, randomWidgetsSequence } = remoteConfigParams;

    if (!seed && seed !== 0) {
      seed = _.random(100000, false);
    }
    const widgetsList = sortRandom(FULL_WIDGETS_LIST, seed);
    const resultWidgetsList = randomWidgetsSequence
      ? widgetsList.filter(x => todayWidgets.includes(x))
      : todayWidgets.filter(x => widgetsList.includes(x));
    dispatch({
      type: TYPES.SET,
      payload: {
        widgets: resultWidgetsList,
        seed: seed,
      },
    });
  };
};

export const resetVisitedWidgetsForNewDate = () => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const lastOpenedDate = getState().widgets.visitedOn;
    if (!lastOpenedDate || !isToday(lastOpenedDate)) {
      dispatch(resetVisited());
    }
  };
};
