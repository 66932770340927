import { Platform } from 'react-native';
import queryString from 'query-string';

export const generatePWAManifest = (idfm: string) => {
  if (Platform.OS !== 'web') {
    return false;
  }

  try {
    const originUrl = `${window.location.origin}`;
    const query = queryString.parse(window?.location?.search);
    query.idfm = idfm;
    const startUrl = queryString.stringifyUrl({
      url: originUrl,
      query,
    });

    const appName = process.env.REACT_APP_TITLE;

    const manifest = {
      name: `${appName}`,
      short_name: `${appName}`,
      start_url: startUrl,
      display: 'standalone',
      orientation: 'portrait',
      theme_color: '#000000',
      background_color: '#000000',
      icons: [
        {
          src: `${originUrl}/icons-192.png`,
          type: 'image/png',
          sizes: '192x192',
          purpose: 'any',
        },
        {
          src: `${originUrl}/icons-192.png`,
          type: 'image/png',
          sizes: '192x192',
          purpose: 'maskable',
        },
        {
          src: `${originUrl}/icons-512.png`,
          type: 'image/png',
          sizes: '512x512',
        },
      ],
    };

    const content = encodeURIComponent(JSON.stringify(manifest));
    const manifestUrl = 'data:application/manifest+json,' + content;

    const element = document.querySelector('#manifest-placeholder');
    element?.setAttribute('href', manifestUrl);
  } catch (e) {
    console.log('> [ERROR] when generate PWA manifest');
  }
};
