import { PROFILE_TYPES } from 'store/profile/types';

import { ActionType, State, TYPES } from './types';

const initialState: State = {
  seconds: 180,
  isTimeUp: false,
  isTimerTooltipVisible: false,
  isTimerTooltipClosed: false,
  isTimePaused: true,
  isAwaitingNewTimeBalance: false,
  lastTransactionDate: null,
  balances: {
    balance: 0,
    freeBalance: 0,
  },
  chatTimer: 0,
  isIncreasingChatTimer: false,
};

export default function (state = initialState, action: ActionType): State {
  switch (action.type) {
    case TYPES.SET_AVAILABLE_SECONDS:
      return {
        ...state,
        seconds: action.payload,
      };
    case TYPES.SET_TOOLTIP_VISIBILITY:
      return {
        ...state,
        isTimerTooltipVisible: action.payload,
      };
    case TYPES.SET_TOOLTIP_CLOSED:
      return {
        ...state,
        isTimerTooltipClosed: action.payload,
      };
    case TYPES.SET_TIME_PAUSED:
      return {
        ...state,
        isTimePaused: action.payload,
      };
    case TYPES.SET_AWAITING_NEW_TIME_BALANCE:
      return {
        ...state,
        isAwaitingNewTimeBalance: action.payload,
      };
    case TYPES.SET_LAST_TRANSACTION_DATE:
      return {
        ...state,
        lastTransactionDate: action.payload,
      };
    case TYPES.SET_BALANCES:
      return {
        ...state,
        balances: action.payload,
      };
    case TYPES.SET_CHAT_TIMER:
      return {
        ...state,
        chatTimer: action.payload,
      };
    case TYPES.SET_INCREASING_CHAT_TIMER:
      return {
        ...state,
        isIncreasingChatTimer: action.payload,
      };
    case TYPES.SET_TIME_UP:
      return {
        ...state,
        isTimeUp: action.payload,
      };
    case PROFILE_TYPES.RESET_USER_DATA:
      return initialState;
    default:
      return state;
  }
}
