export enum AUTH_TYPES {
  SET_USER_CREDENTIALS = 'AUTH/SET_USER_CREDENTIALS',
  RESET_DATA = 'AUTH/RESET_DATA',
  SET_WEB_UUID = 'AUTH/SET_WEB_UUID',
  SET_ONE_TIME_TOKEN = 'AUTH/SET_ONE_TIME_TOKEN',
  SET_FIREBASE_EMAIL = 'AUTH/SET_FIREBASE_EMAIL',
  SET_COMPLETE_REGISTRATION_SHOW_COUNTER = 'AUTH/SET_COMPLETE_REGISTRATION_SHOW_COUNTER',
  SET_IS_REGISTRATION_COMPLETED = 'AUTH/SET_IS_REGISTRATION_COMPLETED',
  SET_FORCE_LOGOUT = 'AUTH/SET_FORCE_LOGOUT',
  SET_COMPLETE_REGISTRATION_SHOWN = 'AUTH/SET_COMPLETE_REGISTRATION_SHOWN',
}

export interface UserCredentials {
  id: string;
  accessToken: string;
}

export interface ProcessWebUuidParams {
  uuid: string;
  token?: string;
  isDeferred?: boolean;
  source?: string | null;
}

export interface FirebaseUser {
  idToken: string;
  uuid?: string;
  email?: string | null;
}

export interface State extends UserCredentials {
  webUUID: string;
  oneTimeToken: string;
  isSuccessful: boolean;
  firebaseEmail: string;
  forceLogout: boolean; // force navigate to AUTHORIZATION
  isRegistrationCompleted: boolean; // has email and password
  completeRegistrationShowsCounter: number;
  isCompleteRegistrationShown: boolean;
}

interface SetUserCredentials {
  type: typeof AUTH_TYPES.SET_USER_CREDENTIALS;
  payload: UserCredentials;
}

interface SetWebUUID {
  type: typeof AUTH_TYPES.SET_WEB_UUID;
  payload: string;
}

interface SetOneTimeToken {
  type: typeof AUTH_TYPES.SET_ONE_TIME_TOKEN;
  payload: string;
}

interface SetFirebaseEmail {
  type: typeof AUTH_TYPES.SET_FIREBASE_EMAIL;
  payload: string;
}

interface ResetData {
  type: typeof AUTH_TYPES.RESET_DATA;
}

interface SetRegistrationCompleted {
  type: typeof AUTH_TYPES.SET_IS_REGISTRATION_COMPLETED;
  payload: boolean;
}

interface SetForceLogout {
  type: typeof AUTH_TYPES.SET_FORCE_LOGOUT;
}

interface SetCompleteRegistrationShows {
  type: typeof AUTH_TYPES.SET_COMPLETE_REGISTRATION_SHOW_COUNTER;
  payload: number;
}

interface SetCompleteRegistrationShown {
  type: typeof AUTH_TYPES.SET_COMPLETE_REGISTRATION_SHOWN;
  payload: boolean;
}

export type ActionType =
  | SetUserCredentials
  | SetWebUUID
  | SetOneTimeToken
  | ResetData
  | SetFirebaseEmail
  | SetRegistrationCompleted
  | SetForceLogout
  | SetCompleteRegistrationShows
  | SetCompleteRegistrationShown;
