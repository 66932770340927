export const SET_CURRENT_ROUTE_NAME = 'NAVIGATION/SET_CURRENT_ROUTE_NAME';
export const SET_PREV_ROUTE_NAME = 'NAVIGATION/SET_PREV_ROUTE_NAME';
export const SET_NAVIGATED_AFTER_ONBOARDING = 'NAVIGATION/SET_NAVIGATED_AFTER_ONBOARDING';
export const SET_IS_PROMO_OFFER_NOTIFICATION_CLICKED = 'NAVIGATION/SET_IS_PROMO_OFFER_NOTIFICATION_CLICKED';

export interface State {
  currentRoute: string;
  previousRoute: string;
  isPromoOfferNotificationClicked: boolean;
  isNavigatedAfterOnboarding: boolean;
}

interface setCurrentRouteName {
  type: typeof SET_CURRENT_ROUTE_NAME;
  payload: string;
}

interface setPreviousRouteName {
  type: typeof SET_PREV_ROUTE_NAME;
  payload: string;
}

interface setNavigatedAfterOnboarding {
  type: typeof SET_NAVIGATED_AFTER_ONBOARDING;
  payload: boolean;
}

interface setPromoOfferNotificationClicked {
  type: typeof SET_IS_PROMO_OFFER_NOTIFICATION_CLICKED;
}

export type ActionTypes = setCurrentRouteName | setPreviousRouteName | setNavigatedAfterOnboarding | setPromoOfferNotificationClicked;
