import { HistoryItem, Chats, Astrologist } from 'api/astrology-chat/constants';
import { START_CHAT_PLACE } from 'screens/advisors/constants';

import { ResetUserData } from '../types';
import { Question } from '../core/types';

export enum TYPES {
  SET_PENDING_CHATS = 'ASTROLOGERS/SET_PENDING_CHATS',
  SET_CHAT_MESSAGES = 'ASTROLOGERS/SET_CHAT_MESSAGES',
  SET_UNREAD_CHAT_COUNT = 'ASTROLOGERS/SET_UNREAD_CHAT_COUNT',
  SET_CHAT_IS_INTERACTING = 'ASTROLOGERS/SET_CHAT_IS_INTERACTING',
  SET_CURRENT_CHAT_ID = 'ASTROLOGERS/SET_CURRENT_CHAT_ID',
  SET_CURRENT_CHATS = 'ASTROLOGERS/SET_CURRENT_CHATS',
  SET_HIDDEN_MESSAGE = 'ASTROLOGERS/SET_HIDDEN_MESSAGE',
  RESET_ALL_CHATS = 'ASTROLOGERS/RESET_ALL_CHATS',
  SET_QUESTION = 'ASTROLOGERS/SET_QUESTION',
  SET_CHAT_REVIEW_COMPLETED = 'ASTROLOGERS/SET_CHAT_REVIEW_COMPLETED',
  SET_START_CHAT_PLACE = 'ASTROLOGERS/SET_START_CHAT_PLACE',
  SET_IS_PALM_READING_OFFER = 'ASTROLOGERS/SET_IS_PALM_READING_OFFER',
  SET_PALM_PHOTO = 'ASTROLOGERS/SET_PALM_PHOTO',
  SET_SUGGESTS_VISIBLE = 'ASTROLOGERS/SET_SUGGESTS_VISIBLE',
  SET_CURRENT_ASTROLOGER_ID = 'ASTROLOGERS/SET_CURRENT_ASTROLOGER_ID',
  SET_WAS_SESSION_CONVERSATION = 'ASTROLOGERS/SET_WAS_SESSION_CONVERSATION',
  SET_PLAYING_VOICE_ID = 'ASTROLOGERS/SET_PLAYING_VOICE_ID',
  SET_REVIEW_MODAL_VISIBLE = 'ASTROLOGERS/SET_REVIEW_MODAL_VISIBLE',
}

export interface State {
  pendingChats: string[];
  chats: {
    [id: string]: {
      messages: HistoryItem[];
      isReviewCompleted: boolean;
      rate: number;
      isTyping: boolean;
      isRecording: boolean;
    };
  };
  unreadMessages: number;
  currentChatId: number;
  currentChats: Chats[];
  hiddenMessage: string;
  question: Question;
  startChatPlace: START_CHAT_PLACE | null;
  currentAstrologerId: Astrologist['astrologer_id'];
  isSuggestsVisible: boolean;
  isPalmReadingOffer: boolean;
  palmPhoto: string;
  wasSessionConversation: boolean;
  playingVoiceId: string | null;
  isReviewModalVisible: boolean;
}

interface SetPendingChats {
  type: typeof TYPES.SET_PENDING_CHATS;
  payload: string[];
}

interface SetChatMessages {
  type: typeof TYPES.SET_CHAT_MESSAGES;
  payload: {
    chatId: string;
    messages: HistoryItem[];
  };
}

interface SetUnreadMessagesCounter {
  type: typeof TYPES.SET_UNREAD_CHAT_COUNT;
  payload: number;
}

interface SetChatIsInteracting {
  type: typeof TYPES.SET_CHAT_IS_INTERACTING;
  payload: {
    chatId: string;
    isTyping?: boolean;
    isRecording?: boolean;
  };
}

interface ResetAllChats {
  type: typeof TYPES.RESET_ALL_CHATS;
}

interface SetCurrentChatId {
  type: typeof TYPES.SET_CURRENT_CHAT_ID;
  payload: number;
}

interface SetCurrentChats {
  type: typeof TYPES.SET_CURRENT_CHATS;
  payload: Chats[];
}

interface SetHiddenMessage {
  type: typeof TYPES.SET_HIDDEN_MESSAGE;
  payload: string;
}

interface SetQuestion {
  type: typeof TYPES.SET_QUESTION;
  payload: Question;
}

interface SetChatReviewCompleted {
  type: typeof TYPES.SET_CHAT_REVIEW_COMPLETED;
  payload: {
    chatId: string;
    isReviewCompleted: boolean;
    rate: number;
  };
}

interface SetStartChatPlace {
  type: typeof TYPES.SET_START_CHAT_PLACE;
  payload: START_CHAT_PLACE;
}

interface SetCurrentAstrologerId {
  type: typeof TYPES.SET_CURRENT_ASTROLOGER_ID;
  payload: Astrologist['astrologer_id'];
}

interface SetSuggestsVisible {
  type: typeof TYPES.SET_SUGGESTS_VISIBLE;
  payload: boolean;
}

interface SetPalmPhoto {
  type: typeof TYPES.SET_PALM_PHOTO;
  payload: string;
}

interface SetIsPalmReadingOffer {
  type: typeof TYPES.SET_IS_PALM_READING_OFFER;
  payload: boolean;
}

interface SetWasSessionConversation {
  type: typeof TYPES.SET_WAS_SESSION_CONVERSATION;
  payload: boolean;
}

interface SetReviewModalVisible {
  type: typeof TYPES.SET_REVIEW_MODAL_VISIBLE;
  payload: boolean;
}

interface SetPlayingVoiceId {
  type: typeof TYPES.SET_PLAYING_VOICE_ID;
  payload: string | null;
}

export type ActionType =
  | SetPendingChats
  | SetChatReviewCompleted
  | SetChatMessages
  | SetUnreadMessagesCounter
  | SetChatIsInteracting
  | ResetAllChats
  | SetCurrentChatId
  | SetCurrentChats
  | SetHiddenMessage
  | SetQuestion
  | SetStartChatPlace
  | SetCurrentAstrologerId
  | SetIsPalmReadingOffer
  | SetPalmPhoto
  | SetSuggestsVisible
  | SetWasSessionConversation
  | ResetUserData
  | SetReviewModalVisible
  | SetPlayingVoiceId;
