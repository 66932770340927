import { PROFILE_TYPES } from 'store/profile/types';

import { Question } from '../core/types';

import { State, ActionType, TYPES } from './types';

const initialState: State = {
  pendingChats: [],
  chats: {},
  unreadMessages: 0,
  currentChatId: 0,
  currentChats: [],
  hiddenMessage: '',
  question: {} as Question,
  startChatPlace: null,
  isSuggestsVisible: true,
  palmPhoto: '',
  isPalmReadingOffer: false,
  currentAstrologerId: '',
  wasSessionConversation: false,
  playingVoiceId: null,
  isReviewModalVisible: false,
};

export default function (state = initialState, action: ActionType): State {
  switch (action.type) {
    case TYPES.SET_HIDDEN_MESSAGE:
      return {
        ...state,
        hiddenMessage: action.payload,
      };
    case TYPES.SET_CURRENT_CHATS:
      return {
        ...state,
        currentChats: action.payload,
      };
    case TYPES.SET_CURRENT_CHAT_ID:
      return {
        ...state,
        currentChatId: action.payload,
      };
    case TYPES.RESET_ALL_CHATS:
      return {
        ...state,
        chats: {},
      };
    case TYPES.SET_UNREAD_CHAT_COUNT:
      return {
        ...state,
        unreadMessages: action.payload,
      };
    case TYPES.SET_PENDING_CHATS:
      return {
        ...state,
        pendingChats: action.payload,
      };
    case TYPES.SET_CHAT_MESSAGES:
      return {
        ...state,
        chats: {
          ...state.chats,
          [action.payload.chatId]: {
            ...state.chats[action.payload.chatId],
            messages: action.payload.messages,
          },
        },
      };
    case TYPES.SET_CHAT_IS_INTERACTING:
      return {
        ...state,
        chats: {
          ...state.chats,
          [action.payload.chatId]: {
            ...state.chats[action.payload.chatId],
            isTyping: !!action.payload?.isTyping,
            isRecording: !!action.payload?.isRecording,
          },
        },
      };
    case TYPES.SET_QUESTION:
      return {
        ...state,
        question: action.payload,
      };
    case TYPES.SET_START_CHAT_PLACE:
      return {
        ...state,
        startChatPlace: action.payload,
      };
    case TYPES.SET_PALM_PHOTO:
      return {
        ...state,
        palmPhoto: action.payload,
      };
    case TYPES.SET_IS_PALM_READING_OFFER:
      return {
        ...state,
        isPalmReadingOffer: action.payload,
      };
    case TYPES.SET_SUGGESTS_VISIBLE:
      return {
        ...state,
        isSuggestsVisible: action.payload,
      };
    case TYPES.SET_CURRENT_ASTROLOGER_ID:
      return {
        ...state,
        currentAstrologerId: action.payload,
      };
    case TYPES.SET_CHAT_REVIEW_COMPLETED:
      return {
        ...state,
        chats: {
          ...state.chats,
          [action.payload.chatId]: {
            ...state.chats[action.payload.chatId],
            isReviewCompleted: action.payload.isReviewCompleted,
            rate: action.payload.rate,
          },
        },
      };
    case TYPES.SET_WAS_SESSION_CONVERSATION:
      return {
        ...state,
        wasSessionConversation: action.payload,
      };
    case TYPES.SET_REVIEW_MODAL_VISIBLE:
      return {
        ...state,
        isReviewModalVisible: action.payload,
      };
    case TYPES.SET_PLAYING_VOICE_ID:
      return {
        ...state,
        playingVoiceId: action.payload,
      };
    case PROFILE_TYPES.RESET_USER_DATA:
      return initialState;
    default:
      return state;
  }
}
